html
{
    overflow: hidden;
}

body
{
    margin: 0;
    box-sizing: border-box;
}

html, body, #root
{
    height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.landing {
    background-color: theme('colors.brand');
}