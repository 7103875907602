@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: theme('colors.white');
}

.flex-pad-scroll::after {
    flex: 0 0 20px;
    content: '';
}