.paging-dot {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
    fill: white;

    > circle {
        r: 6px;
        cx: 10px;
        cy: 10px;
    }
}

.slider-list {
    display: flex;
}