.svg{
    display: block;
    max-width: 100%;
}
  
.svg-circle-bg {
    fill: none;
}
  
.svg-circle {
    fill: none;
}

.svg-circle-text {
    font-size: 2rem;
    text-anchor: middle;
    fill: theme('colors.brand');
    font-weight: bold;
}