.reporting {
    .stats-card {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
        background-color: white;
        color: theme('colors.brand');

        .stats-text {
            color: #666666;
            font-weight: bold;
            position: relative;

            .drop-icon {
                margin-left: 2px;
            }

            .drop-list {
                background-color: white;
                position: absolute;
                right: 0;
                margin-top: 5px;
                padding: 10px;
                padding-top: 6px;
                padding-bottom: 6px;
                box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
                border-radius: 6px;

                .drop-list-item {
                    cursor: pointer;
                    margin-top: 5px;
                    margin-bottom: 5px;

                    &:hover {
                        color: theme('colors.brand');
                    }
                }
            }
        }

        .text-xxlg {
            font-size: 4em;
            font-weight: 300;
        }

        &.stats-card-text {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    .admin-button {
        margin-left: auto;
        padding-left: 50px;
        padding-right: 50px;
    }
}